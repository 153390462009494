<template>
  <div class="root" :class="className">
    <div :class="'overlay-transparent ' + (searchCourse.length >= 3 ? 'active' : '')" @click="searchCourse = ''"></div>
    <div class="search">
      <IconSax size="1rem" name="search-normal" class="search-img" @click="getCourse" />
      <input autocomplete="one-time-code" v-model="searchCourse" v-on:keyup="handleInput" v-on:keydown.enter="getCourse" class="search-input"
        :placeholder="$t('search_modal.label')" autofocus />
      <IconSax v-if="searchCourse.length > 1" name="close" class="search-img"
        @click="searchCourse = ''" />
    </div>
    <div v-if="searchCourse.length >= 3" class="search-items">
      <div class="search-items-content">
        <div class="search-content-items">
          <p class="search-group-title" v-if="courses.length !== 0">
            {{ $t("search_modal.text4") }}
          </p>
          <div v-for="course in courses" :key="course.id" class="spaceCourse">
            <span @click="getEvent()">
              <router-link v-if="(course.has_access === true && course.is_liberated === true) ||
                permission
                " :to="{ name: 'course', params: { courseId: course.id } }" class="removeLink">
                <SearchItem :typeSelectedItem="course" :itemid="course.id" :isMobile="isMobile" :content="course"
                  :permission="permission" :ratingHome="ratingHome" :type="'module'" />
              </router-link>
              <a v-else class="removeLink" @click="openModalBuy(course)">
                <SearchItem :typeSelectedItem="course" :itemid="course.id" :isMobile="isMobile" :content="course"
                  :permission="permission" :ratingHome="ratingHome" :type="'module'" />
              </a>
            </span>
          </div>
        </div>
        <div class="search-content-items">
          <p class="search-group-title" v-if="modules.length !== 0">
            {{ $t("search_modal.text5") }}
          </p>
          <div v-for="modul in modules" :key="modul.id" class="spaceCourse">
            <span @click="getEvent()">
              <router-link v-if="(modul.course?.has_access == true &&
                modul.is_liberated == true) ||
                permission
                " :to="{
    name: 'course-module',
    params: { courseId: modul.course_id, moduleId: modul.id },
  }" class="removeLink">
                <SearchItem :typeSelectedItem="modul" :itemid="modul.id" :isMobile="isMobile" :content="modul"
                  :permission="permission" :ratingHome="ratingHome" :type="'module'" />
              </router-link>
              <a v-else class="removeLink" @click="openModalBuy(modul)">
                <SearchItem :typeSelectedItem="modul" :itemid="modul.id" :isMobile="isMobile" :content="modul"
                  :permission="permission" :ratingHome="ratingHome" :type="'module'" />
              </a>
            </span>
          </div>
        </div>
        <div class="search-content-items">
          <p v-if="lessons.length !== 0" class="search-group-title">
            {{ $t("search_modal.text6") }}
          </p>
          <div v-for="lesson in lessons" :key="lesson.id" class="spaceCourse">
            <span @click="getEvent()">

              <router-link v-if="(lesson.course?.has_access === true &&
                lesson.is_liberated === true) ||
                permission
                " :to="{
    name: 'course-lesson',
    params: {
      courseId: lesson.course_id,
      moduleId: lesson.module_id,
      lessonId: lesson.id,
    },
  }" class="removeLink">
                <SearchItem :typeSelectedItem="lesson" :itemid="lesson.id" :isMobile="isMobile" :content="lesson"
                  :permission="permission" :ratingHome="ratingHome" :type="'lesson'" />
              </router-link>
              <a v-else class="removeLink" @click="openModalBuy(lesson)">
                <SearchItem :typeSelectedItem="lesson" :itemid="lesson.id" :isMobile="isMobile" :content="lesson"
                  :permission="permission" :ratingHome="ratingHome" :type="'lesson'" />
              </a>
            </span>
          </div>
        </div>
        <div class="search-content-items">
          <p v-if="lessons.length === 0 && modules.length === 0 && courses.length === 0 && !loading"
            class="search-group-title">
            {{ $t("search_modal.no-content") }}
          </p>
          <p v-if="loading" class="search-group-title">
            {{ $t("helper.modal.loading") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SearchItem from "./SearchItem.vue";
import notify from "@/services/libs/notificacao";

import CourseService from "@/services/resources/CourseService";
import SearchService from "@/services/resources/SearchService";
import MetaService from "@/services/resources/MetaService";
import _ from 'lodash';

const serviceCourse = CourseService.build();
const serviceSearch = SearchService.build();
const serviceMeta = MetaService.build();

export default {
  props: ["isMobile", "permission", "className"],
  components: { SearchItem },
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings",
      getTheme: 'config/getTheme',
      getMe: "auth/getMe",

    }),
    courses() {
      return this.getAllSearch["App\\Models\\Course"] || []
    },
    modules() {
      return this.getAllSearch["App\\Models\\Module"]?.filter(m => m.course !== null) || []
    },
    lessons() {
      return this.getAllSearch["App\\Models\\Lesson"] || []
    }
  },

  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 27) {
        that.openSearch = false;
      } else if (evt.keyCode === 40) {
        that.getNextItem();
      } else if (evt.keyCode === 38) {
        that.getLastItem();
      }
    });
  },
  data() {
    return {
      openSearch: false,
      searchCourse: "",
      getAllSearch: {},
      homeVertical: "16:9",
      ratingHome: true,
      selectedItem: null,
      typeSelectedItem: "",
      loading: true,
    };
  },
  methods: {
    handleInput: _.debounce(function () {
      this.getCourse();
    }, 1000),
    openModalBuy(data) {
      this.openSearch = false;
      if (data.is_liberated === false) {
        notify("erro", this.$t("search_modal.error.liberation"));
      } else {
        notify("erro", this.$t("search_modal.error.access"));
        let data = data.id + "/meta?keys[]=tipo_venda";
        serviceCourse
          .read(data)
          .then((resp) => {
            if (resp.tipo_venda === "tela") {
              this.$router.push("/curso/venda/" + data.id);
            } else {
              this.$root.$emit("modal-buy-course", data);
              this.$root.$emit("bv::show::modal", "modal-buy", "#btnShow");
            }
          })
          .catch((err) => console.log(err));
      }
    },
    getHomeVertical() {
      if (this.getSettings.home_vertical === false || this.getSettings.home_vertical !== null) {
        this.homeVertical = "16:9";
      } else {
        this.homeVertical = "5:7";
      }

    },
    getEvent() {
      if (this.$route.name === 'course-lesson' || this.$route.name === 'course-module' || this.$route.name === 'course') {
        this.$router.go()
      }
    },
    getRating() {
      serviceMeta
        .search("keys[]=rating_home")
        .then((resp) => {
          if (resp.rating_home === null || resp.rating_home === "on") {
            this.ratingHome = true;
          } else {
            this.ratingHome = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.ratingHome = true;
        });
    },
    async getCourse() {
      if (this.searchCourse.length < 3) {
        this.getAllSearch = {};
        return
      }
      this.loading = true;
      try {
        this.getAllSearch = await serviceSearch.search2("search_string=" + this.searchCourse)
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getHomeVertical();
    this.$root.$on("openSearch", () => {
      this.openSearch = true;
    });
  },
};
</script>

<style scoped lang="scss">
.overlay-transparent {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: none;

  &.active {
    display: block;
  }
}

.root {
  position: relative;
  width: 22.5rem;
}

.search {
  position: relative;
  display: flex;
  width: 100%;
  height: 2.5rem;
  padding: 0rem 0.625rem;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background-color: var(--search-background);
  border: 1px solid var(--search-background);
  transition: border 0.3s ease;

  &:hover {
    border: 1px solid var(--neutral-gray-500);
  }
}

.search-input {
  @include input-unstyle;
  flex: 1;
  color: var(--search-color);
  z-index: 1001;
}

.search-img {
  flex-shrink: 0;
  cursor: pointer;
  z-index: 1001;
}

.search-items {
  @include animation-opacity;
  position: absolute;
  top: 2.8rem;
  z-index: 1001;
  display: flex;
  width: 22.5rem;
  height: 19.9375rem;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  // gap: 2.5rem;
  flex-shrink: 0;

  border-radius: 0.3125rem;
  border: 1px solid var(--bordercolor, #404043);
  background: var(--search-background, #fff);
  /* Shadow D */
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
}

.spaceCourse {
  padding: 4px 0px;
  cursor: pointer;
}

.spaceCourse.selectedItem {
  background: #e8e8e8;
}

.search-items-content {
  height: 100%;
  width: 100%;
  transition: 0.3s;
  overflow: auto;
  border-radius: 3px;
}

.search-group-title {
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  color: var(--opposite-color);
  margin-top: 25px;
  margin-bottom: 0;
  padding: 0;
}

.removeLink {
  text-decoration: none;
}

.search-items-content::-webkit-scrollbar {
  background-color: #fff;
  width: 2px;
}

.search-items-content::-webkit-scrollbar-track {
  background-color: #c7c7c7;
}

.search-items-content::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  border-radius: 31px;
}

.search-items-content::-webkit-scrollbar-button {
  display: none;
}

.spaceCourse {
  a {
    height: 100%;
  }
}

.search-content-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
</style>
