var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"root",class:_vm.className},[_c('div',{class:'overlay-transparent ' + (_vm.searchCourse.length >= 3 ? 'active' : ''),on:{"click":function($event){_vm.searchCourse = ''}}}),_c('div',{staticClass:"search"},[_c('IconSax',{staticClass:"search-img",attrs:{"size":"1rem","name":"search-normal"},on:{"click":_vm.getCourse}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchCourse),expression:"searchCourse"}],staticClass:"search-input",attrs:{"autocomplete":"one-time-code","placeholder":_vm.$t('search_modal.label'),"autofocus":""},domProps:{"value":(_vm.searchCourse)},on:{"keyup":_vm.handleInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getCourse.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.searchCourse=$event.target.value}}}),(_vm.searchCourse.length > 1)?_c('IconSax',{staticClass:"search-img",attrs:{"name":"close"},on:{"click":function($event){_vm.searchCourse = ''}}}):_vm._e()],1),(_vm.searchCourse.length >= 3)?_c('div',{staticClass:"search-items"},[_c('div',{staticClass:"search-items-content"},[_c('div',{staticClass:"search-content-items"},[(_vm.courses.length !== 0)?_c('p',{staticClass:"search-group-title"},[_vm._v(" "+_vm._s(_vm.$t("search_modal.text4"))+" ")]):_vm._e(),_vm._l((_vm.courses),function(course){return _c('div',{key:course.id,staticClass:"spaceCourse"},[_c('span',{on:{"click":function($event){return _vm.getEvent()}}},[((course.has_access === true && course.is_liberated === true) ||
              _vm.permission
              )?_c('router-link',{staticClass:"removeLink",attrs:{"to":{ name: 'course', params: { courseId: course.id } }}},[_c('SearchItem',{attrs:{"typeSelectedItem":course,"itemid":course.id,"isMobile":_vm.isMobile,"content":course,"permission":_vm.permission,"ratingHome":_vm.ratingHome,"type":'module'}})],1):_c('a',{staticClass:"removeLink",on:{"click":function($event){return _vm.openModalBuy(course)}}},[_c('SearchItem',{attrs:{"typeSelectedItem":course,"itemid":course.id,"isMobile":_vm.isMobile,"content":course,"permission":_vm.permission,"ratingHome":_vm.ratingHome,"type":'module'}})],1)],1)])})],2),_c('div',{staticClass:"search-content-items"},[(_vm.modules.length !== 0)?_c('p',{staticClass:"search-group-title"},[_vm._v(" "+_vm._s(_vm.$t("search_modal.text5"))+" ")]):_vm._e(),_vm._l((_vm.modules),function(modul){return _c('div',{key:modul.id,staticClass:"spaceCourse"},[_c('span',{on:{"click":function($event){return _vm.getEvent()}}},[((modul.course?.has_access == true &&
              modul.is_liberated == true) ||
              _vm.permission
              )?_c('router-link',{staticClass:"removeLink",attrs:{"to":{
  name: 'course-module',
  params: { courseId: modul.course_id, moduleId: modul.id },
}}},[_c('SearchItem',{attrs:{"typeSelectedItem":modul,"itemid":modul.id,"isMobile":_vm.isMobile,"content":modul,"permission":_vm.permission,"ratingHome":_vm.ratingHome,"type":'module'}})],1):_c('a',{staticClass:"removeLink",on:{"click":function($event){return _vm.openModalBuy(modul)}}},[_c('SearchItem',{attrs:{"typeSelectedItem":modul,"itemid":modul.id,"isMobile":_vm.isMobile,"content":modul,"permission":_vm.permission,"ratingHome":_vm.ratingHome,"type":'module'}})],1)],1)])})],2),_c('div',{staticClass:"search-content-items"},[(_vm.lessons.length !== 0)?_c('p',{staticClass:"search-group-title"},[_vm._v(" "+_vm._s(_vm.$t("search_modal.text6"))+" ")]):_vm._e(),_vm._l((_vm.lessons),function(lesson){return _c('div',{key:lesson.id,staticClass:"spaceCourse"},[_c('span',{on:{"click":function($event){return _vm.getEvent()}}},[((lesson.course?.has_access === true &&
              lesson.is_liberated === true) ||
              _vm.permission
              )?_c('router-link',{staticClass:"removeLink",attrs:{"to":{
  name: 'course-lesson',
  params: {
    courseId: lesson.course_id,
    moduleId: lesson.module_id,
    lessonId: lesson.id,
  },
}}},[_c('SearchItem',{attrs:{"typeSelectedItem":lesson,"itemid":lesson.id,"isMobile":_vm.isMobile,"content":lesson,"permission":_vm.permission,"ratingHome":_vm.ratingHome,"type":'lesson'}})],1):_c('a',{staticClass:"removeLink",on:{"click":function($event){return _vm.openModalBuy(lesson)}}},[_c('SearchItem',{attrs:{"typeSelectedItem":lesson,"itemid":lesson.id,"isMobile":_vm.isMobile,"content":lesson,"permission":_vm.permission,"ratingHome":_vm.ratingHome,"type":'lesson'}})],1)],1)])})],2),_c('div',{staticClass:"search-content-items"},[(_vm.lessons.length === 0 && _vm.modules.length === 0 && _vm.courses.length === 0 && !_vm.loading)?_c('p',{staticClass:"search-group-title"},[_vm._v(" "+_vm._s(_vm.$t("search_modal.no-content"))+" ")]):_vm._e(),(_vm.loading)?_c('p',{staticClass:"search-group-title"},[_vm._v(" "+_vm._s(_vm.$t("helper.modal.loading"))+" ")]):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }