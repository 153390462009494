<template>
  <span class="spaceList">
    <div class="content">
      <p :class="{ 
        'content__text': true,
        'content__text--selected': itemid === content.id && typeSelectedItem.title === content.title 
      }">
        {{ content.title }}
      </p>
    </div>
  </span>
</template>
  
<script>

export default {
  name: 'CourseHome',
  props: ['typeSelectedItem', 'itemid', 'content', 'permission', 'ratingHome', 'type', 'isMobile'],
  methods: {

  }
}
</script>
  
<style lang="scss" scoped>
.spaceList {
  width: 100%;
  display: flex;
}

.content {
  display: flex;
  justify-content: space-between;
  flex: 1;
  font-weight: normal;
}

.content__text--selected {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  margin: 0;
  display: flex;
  align-self: center;
  color: var(--fontcolor);

  &:hover {
    color: var(--fontcolor-hover);
  }
}

.itemSelected {
  display: flex;
  align-items: center;

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #333333;
    margin-right: 4px;
  }
}

.imagem {
  height: 40px;
  overflow: hidden;
  width: 40px;
  margin-right: 5px;

  img {
    border-radius: 3px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
  